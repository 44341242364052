import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home/home.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {AuthGuard} from './services/guards/auth-guard.service';
import {ExpressGuard} from './user/services/express-guard';

const routes: Routes = [
    {path: '', redirectTo: 'user', pathMatch: 'full'},
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard]
    },
    {path: 'user-profile', component: UserProfileComponent},
    {path: 'user-profile/:subname', component: UserProfileComponent},
    {
        path: 'policy-dashboard/:policyNumber',
        loadChildren: () => import('./policy-dashboard/policy-dashboard.module').then(m => m.PolicyDashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'express-fnol/:policyNumber',
        loadChildren: () => import('./policy-dashboard/claims/claims.module').then(m => m.ClaimsModule),
        canActivate: [ExpressGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
