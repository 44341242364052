import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {EnvConfig} from '../../services/env-config';
import {HttpErrorHandlerService} from '../../services/http-error-handler.service';
import {SessionTimeOutModalService} from '../sessiontimeout-modal/sessiontimeout-modal.service';

@Injectable()
export class UpdateRecurringConfirmService {

    constructor(private http: HttpClient, private env: EnvConfig,
                private errorHandler: HttpErrorHandlerService,
                private modalService: SessionTimeOutModalService,
                private authService: AuthService) {
    }

    private baseUrl = this.env.getUrl().apiURL;

    public canUpdatePaymentInformation(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/oasis/api/customerBilling/canUpdatePaymentInformation`;
        return this.http.post(apiUrl, data)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        const errResponse = throwError(() => error.error);
        return errResponse;
    }

}
