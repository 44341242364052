import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {UserProfileService} from "../../services/user-profile.service";
import {UserService} from "../../user/services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {GenericService} from "../../services/generic.service";
import {LoaderService} from "../../services/loader.service";
import {ModalHelperService} from "../../shared/custom-modal/modal-helper.service";
import {PhonePipe} from "../../shared/phone/phone-number.pipe";
import {LocationStrategy} from "@angular/common";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-go-digital-modal',
    templateUrl: './go-digital-modal.component.html',
    styleUrls: ['./go-digital-modal.component.scss']
})
export class GoDigitalModalComponent implements OnInit, OnChanges {
    @ViewChild('goDigitalModal') goDigitalModal: ModalDirective;
    @ViewChild('digitalDetails') digitalDetails: ModalDirective;

    isChecked = false;
    payload: any;
    eDocsTandC: any;
    showPhoneErrMsg: any;
    phoneNumber: any;
    cellPhoneNumber: any;
    _policyList: any;
    email: any;
    edocsUnenrolledPolicyNumbers: any;

    @Input() activeSSAModal: any;
    showMobileErrMsg: any;
    validMobile: boolean;
    mobileNumber: any;

    constructor(
        private userProfileService: UserProfileService,
        private userService: UserService,
        private route: ActivatedRoute,
        private genericService: GenericService,
        private router: Router,
        private spinner: LoaderService,
        private modalService: ModalHelperService,
        private phonePipe: PhonePipe,
        private locationStrategy: LocationStrategy
    ) {
    }

    goDigitalFormSubmitted = false;
    goDigitalForm = new FormGroup({
        phone: new FormControl('', [Validators.pattern("^\\d{3}[\\-]\\d{3}[\\-]\\d{4}$")])
    });

    ngOnInit() {
        this.preventBackButton();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.goDigitalModal) {
            this.activeSSAModal = changes?.activeSSAModal?.currentValue;
            this.initializeModal();
        }
    }

    @Input() set policyList(value: any) {
        if (value?.edocsUnenrolledPolicyNumbers?.length > 0) {
            this._policyList = value;
            this.edocsUnenrolledPolicyNumbers = this._policyList?.edocsUnenrolledPolicyNumbers;
            this.cellPhoneNumber = this._policyList.cellPhoneNumber
        }
    };

    get policyList() {
        return this._policyList;
    }

    initializeModal() {
        // close other modals
        if (this.modalService.bsModalRef) {
            this.modalService.bsModalRef.hide();
        }
        if (this.edocsUnenrolledPolicyNumbers?.length > 0 && !this.activeSSAModal) {
            this.goDigitalModal.show();
        }
    }

    getUserDetails() {
        this.spinner.showLoader(true);
        this.userProfileService.getUserProfileData(localStorage.getItem('username')).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.payload) {
                    this.email = res.payload.email;
                    // this.userProfileData = res.payload;
                }
            },
            error => {
                this.spinner.showLoader(false);
            });

    }

    getEdocsTandC() {
        this.spinner.showLoader(true);
        this.genericService.termsAndConditions('EDOC').subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                this.eDocsTandC = res;
            },
            error => {
                this.spinner.showLoader(false);
            });
    }

    eDocsEnrollment() {
        this.spinner.showLoader(true);
        this.payload = {
            "eDocs": {
                "T&Cid": this.eDocsTandC,
                "policyNumbers": this.edocsUnenrolledPolicyNumbers,
                "username": localStorage.getItem('username'),
                "enroll": true,
                "userId": localStorage.getItem('customerId'),
            }
        };
        if (this.mobileNumber) {
            this.validateMobile(this.mobileNumber);
        } else {
            this.eDocsGlobalEnrollment();
        }
    }

    eDocsGlobalEnrollment() {
        this.userProfileService.eDocsGlobalEnrollment(this.payload).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.status === 'SUCCESS') {
                    this.getUserDetails();
                    this.goDigitalModal.hide();
                    this.genericService.setCustomerByNameData(null);
                    // this.loadPolicyData();
                    this.digitalDetails.show();
                } else if (res.status === 'ERROR') {
                    this.modalService.showConfirm('ERROR', res.message || 'Error while enrolling in edocs. Please try again after some time.', 'ok');
                }
            },
            error => {
                this.spinner.showLoader(false);
            })
    }

    eDocsNoThanks() {
        this.spinner.showLoader(true);

        this.payload = {
            "eDocs": {
                "T&Cid": this.eDocsTandC,
                "policyNumbers": this.edocsUnenrolledPolicyNumbers,
                "username": localStorage.getItem('username'),
                "userId": localStorage.getItem("customerId"),
                "enroll": false
            }
        };
        this.userProfileService.eDocsGlobalEnrollment(this.payload).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.status == 'SUCCESS') {
                    this.goDigitalModal.hide();
                    this.genericService.setCustomerByNameData(null);
                    // this.loadPolicyData();
                } else if (res.status === 'ERROR') {
                    this.modalService.showConfirm('ERROR', res.message || 'Error while submitting No Thanks. Please try again after some time.', 'ok');
                }
            },
            error => {
                this.spinner.showLoader(false);
            })
    }

    mobileValue(val: any) {
        this.mobileNumber = val;
    }

    validateMobile(val: any) {
        this.validMobile = false;
        this.showMobileErrMsg = null;
        this.showPhoneErrMsg = null;
        if (val) {
            let mobileNumberArray: any = [];
            const regex = /^\d{3}[\-]\d{3}[\-]\d{4}$/;
            if (!(regex.test(val))) {
                this.showMobileErrMsg = 'Please enter a valid phone number';
            } else {
                mobileNumberArray.push({'phoneNumber': val});
                this.spinner.showLoader(true);
                this.genericService.validatePhone({'mobile': mobileNumberArray})
                    .subscribe((res: any) => {
                        this.spinner.showLoader(false);
                        if (res.message != null) {
                            this.showPhoneErrMsg = res.message;
                        } else {
                            this.spinner.showLoader(true);
                            this.showPhoneErrMsg = null;
                            this.validMobile = true;
                            this.payload.eDocs.cellPhoneNumber = this.mobileNumber;
                            this.eDocsGlobalEnrollment();
                        }
                    });
            }
        }
    }

    preventBackButton() {
        history.pushState(null, '', location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, '', location.href);
        })
    }


    restrictBackspace(key: any) {
        if (key.keyCode == 32) {
            return false;
        }
        return;
    }
}
