import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PreSignedURLRequest} from './file-upload.model';
import {Injectable} from "@angular/core";
import {EnvConfig} from "../../services/env-config";

@Injectable()

export class FileUploadService {
    files: any[] = [];
    private baseUrl = this.env.getUrl().apiURL;

    constructor(private http: HttpClient,
                private env: EnvConfig) {
    }

    uploadFileDetails(preSignedURLRequest: PreSignedURLRequest, subSystem?: any) {
        let httpHeaders = this.env.getHttpHeaders();
        const apiUrl = `${this.baseUrl}/proxyservice/citadel/api/externalAccess/insured/getPreSignedUploadUrl`;
        return this.http.post<any>(apiUrl, preSignedURLRequest, {
            observe: 'response',
            headers: (subSystem && subSystem?.toLowerCase() === 'expressfnol') ? {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.claimSessionId,
                'x-session-id': localStorage.clientSessionId,
                'Authorization': `Bearer ${localStorage.xauthToken}`
            } : httpHeaders?.headers
        });
    }

    uploadfileAWSS3(preSignedUrl: any, contentType: any, file: any): Observable<any> {
        //this will be used to upload all contenttype files to AWS S3
        const headers = new HttpHeaders({
            'Content-Type': contentType
        });
        const req = new HttpRequest(
            'PUT',
            preSignedUrl,
            file,
            {
                headers: headers,
                reportProgress: true, //This is required for track upload process
            });
        return this.http.request(req);
    }

    saveUploadedMetaDataDetails(uploadedMetaDataInfo: any, subSystem?: any) {
        let httpHeaders = this.env.getHttpHeaders();
        const apiUrl = `${this.baseUrl}/proxyservice/citadel/api/externalAccess/insured/saveUploadedDocumentMetaData`;
        return this.http.post<any>(apiUrl, uploadedMetaDataInfo, {
            observe: 'response',
            headers: (subSystem && subSystem?.toLowerCase() === 'expressfnol') ? {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.claimSessionId,
                'x-session-id': localStorage.clientSessionId,
                'Authorization': `Bearer ${localStorage.xauthToken}`
            } : httpHeaders?.headers
        });
    }
}
