import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {SessionTimeOutModalService} from '../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import {AuthService} from './auth.service';
import {throwError} from 'rxjs';

@Injectable()
export class HttpErrorHandlerService {

    constructor(private modalService: SessionTimeOutModalService, private authService: AuthService) {
    }

    handleError(error: HttpErrorResponse, modalService: SessionTimeOutModalService, authService: AuthService, isExpressFnol?: any) {
        console.log("error=" + error)
        const errResponse: any = throwError(() => error);
        authService.turnOffSpinner();
        if (isExpressFnol) {
            if (error?.error?.error === 'invalid_token' || error?.error?.status === 403 || error?.status === 403 || error?.status === 401) {
                localStorage.clear();
                sessionStorage.clear();
                let warningMsg = '';
                let btnMsg = 'ok';
                let heardMsg = '';
                if (error?.error?.status === 403 || error?.status === 403 || error?.status === 401) {
                    // tslint:disable-next-line:max-line-length
                    warningMsg = 'It appears that you don\'t have any access to view or perform actions on this page. Please make sure you\'re authorized to view this content';
                    btnMsg = 'ok';
                    heardMsg = 'UNAUTHORIZED ACCESS';
                } else {
                    // tslint:disable-next-line:max-line-length
                    warningMsg = 'Due to inactivity, your current session has been expired. Please try again.';
                    btnMsg = 'ok';
                    heardMsg = 'INFO';
                }
                modalService.showConfirm(heardMsg, warningMsg, btnMsg);
                modalService.closeModal.subscribe(
                    (modalRes: any) => {
                        if (modalRes) {
                            modalService.setCloseModal(false);
                            location.replace('#/user/login');
                        }
                    }
                );
            } else {
                return errResponse;
            }
        }

        if (error?.error?.error !== undefined && error?.error?.error !== null || error?.error?.status === 403 || error?.status === 403 || error?.status === 401) {
            if (error?.error?.error === 'invalid_token' || error?.error?.status === 403 || error?.status === 403 || error?.status === 401) {
                if (localStorage.getItem('username') !== null && localStorage.getItem('username') !== '') {
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'x-session-id': localStorage.clientSessionId,
                            'x-user-id': localStorage.username,
                            'Authorization': `Bearer ${localStorage.oauthToken}`
                        })
                    };
                    localStorage.clear();
                    sessionStorage.clear();
                    let warningMsg;
                    let btnMsg;
                    let heardMsg;
                    if (error?.error?.status === 403 || error?.status === 403) {
                        // tslint:disable-next-line:max-line-length
                        warningMsg = 'It appears that you don\'t have any access to view or perform actions on this page. Please make sure you\'re authorized to view this content';
                        btnMsg = 'ok';
                        heardMsg = 'UNAUTHORIZED ACCESS';
                    } else {
                        // tslint:disable-next-line:max-line-length
                        warningMsg = 'You have been automatically logged out. Due to inactivity, your current session has been expired. Please click below to log back in.';
                        btnMsg = 'Log In';
                        heardMsg = 'INFO';
                    }
                    modalService.showConfirm(heardMsg, warningMsg, btnMsg);
                    modalService.closeModal.subscribe(
                        (modalRes: any) => {
                            if (modalRes) {
                                modalService.setCloseModal(false);
                                location.replace('#/user/login');
                                authService.setLogInStatus(false);
                                // NOTE: Irrespective of service response('SUCCESS' or 'ERROR'), throw user back to login page
                                authService.logOutForSessionExpiry(httpOptions).subscribe((res: any) => {
                                }, err => {
                                });
                            }
                        }
                    );
                } else {
                    return errResponse;
                }
            } else {
                return errResponse;
            }
        } else {
            return errResponse;
        }
    }
}
