import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {ModalDirective} from "ngx-bootstrap/modal";
import {ModalHelperService} from "../../shared/custom-modal/modal-helper.service";
import {GenericService} from "../../services/generic.service";
import {LoaderService} from "../../services/loader.service";
import {LocationStrategy} from "@angular/common";

@Component({
    selector: 'subscriber-savings-agreement-modal',
    templateUrl: './subscriber-savings-agreement-modal.component.html',
    styleUrls: ['./subscriber-savings-agreement-modal.component.scss']
})
export class SubscriberSavingsAgreementModalComponent implements OnInit {
    private _policyList: any[];
    subscriberSavingsAgreementUrl: string;
    @Output() activeSSAModal = new EventEmitter<any>();

    @Input() set policyList(value: any[]) {
        if (value && value.length > 0) {
            this._policyList = value;
            this.initializeModal();
        }
    };

    get policyList() {
        return this._policyList;
    }

    @ViewChild('subscriberSavingsAgreementModal') subscriberSavingsAgreementModal: ModalDirective;
    subscriberSavingsAgreementPolicyList: any = [];
    errorMessage = false;

    constructor(
        private modalService: ModalHelperService,
        private genericService: GenericService,
        private spinner: LoaderService,
        private locationStrategy: LocationStrategy) {
    }


    ngOnInit() {
        this.preventBackButton();
    }

    initializeModal() {
        this.subscriberSavingsAgreementPolicyList = [];
        this.policyList.forEach((policy: any) => {
            if (policy.subscriberSavingsAgreementRequired === true) {
                this.subscriberSavingsAgreementPolicyList.push(policy.policyNumber);
                // subscriberSavingsAgreementDocumentUrl will be same for all the policies that requires agreement
                this.subscriberSavingsAgreementUrl = policy.subscriberSavingsAgreementDocumentUrl;
            }
        });

        if (this.subscriberSavingsAgreementPolicyList.length > 0) {
            // close other modals
            if (this.modalService.bsModalRef) {
                this.modalService.bsModalRef.hide();
            }
            this.subscriberSavingsAgreementModal.show();
            this.activeSSAModal.emit(true);
        } else {
            this.activeSSAModal.emit(false);
        }
    }

    subscriberSavingsAgreementModalSubmit() {
        this.spinner.showLoader(true);
        this.genericService.signSubscriberSavingsAgreement(
            {
                signed: true,
                policyNums: this.subscriberSavingsAgreementPolicyList
            }
        ).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.success === true) {
                    this.subscriberSavingsAgreementModal.hide();
                    this.activeSSAModal.emit(false);
                } else {
                    this.errorMessage = true;
                }
            },
            error => {
                this.spinner.showLoader(false);
            });
    }


    subscriberSavingsAgreementModalShowMeLater() {
        this.subscriberSavingsAgreementModal.hide();
        this.activeSSAModal.emit(false);
    }

    preventBackButton() {
        history.pushState(null, '', location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, '', location.href);
        })
    }

}
