import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {Router} from '@angular/router';

import {GenericService} from '../services/generic.service';
import {UserService} from '../user/services/user.service';
import {AuthService} from '../services/auth.service';
import {LoaderService} from '../services/loader.service';
import {HttpHeaders} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserProfileService} from "../services/user-profile.service";
import {ModalHelperService} from "../shared/custom-modal/modal-helper.service";
import {MainMenuService} from "./main-menu.service";

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
    @ViewChild('contactUs') contactUs: ModalDirective;
    @ViewChild('mobileMenu') mobileMenu: ModalDirective;
    @ViewChild('HTMLChatForm') HTMLChatForm: ElementRef;
    @ViewChild('chat') chatModal: ModalDirective;
    isLoggedIn: boolean;

    // TP48272 : Variables used for Chat application
    isChatEnabled = false;
    chatFormSubmitted = false;
    chatForm = new FormGroup({
        extensionField_Name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z ]+$')]),
        extensionField_Policy_Number: new FormControl('', [Validators.minLength(10),
            Validators.pattern('^[A-Za-z0-9]+$')]),
        extensionField_AddressLine1: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9 \'\-.,’"]+$')]),
        extensionField_City_State: new FormControl('', [Validators.pattern('^[A-Za-z0-9 \'\-.,’]+$')]),
        extensionField_Email: new FormControl(''),
        extensionField_ccxqueuetag: new FormControl('Chat_Csq15'),
    });
    policyList: any;
    userEmail: any;

    contactInfo: any;
    policyDetails: any;
    emailErrMsg = '';
    divisions: any[];
    isSpire: boolean;
    firstName: string;
    lastName: string;
    payload = {};
    defaultNotificationFilter: string = 'NONARCHIVED';
    notificationsPayload: any;
    notificationDetails: any;
    selectedFilter: string;
    selectedNotification: any;
    openNotificationWarningModal: boolean = false;
    modalMessage: any;
    showFiltersPanel: boolean = false;
    showNotificationsPanel: boolean = true;
    context: any;
    filterOptionsForProfileAlerts: any[] = [
        {id: "ARCHIVED", description: "Archived", checked: false},
        {id: "ALL", description: "All", checked: false},
    ];


    constructor(
        private genericService: GenericService,
        private authService: AuthService,
        private userService: UserService,
        private userProfileService: UserProfileService,
        private modalService: ModalHelperService,
        private mainMenuService: MainMenuService,
        private spinner: LoaderService,
        private router: Router) {
    }

    ngOnInit() {
        this.authService.loggedInObsv.subscribe((res: any) => {
            this.isLoggedIn = res;
        });
        this.genericService.customerDataAvailable.subscribe((val: boolean) => {
            if (val) {
                const serviceData = this.genericService.customerData;
                this.firstName = serviceData.firstName;
                this.lastName = serviceData.lastName;
                this.policyDetails = serviceData.policies;
                this.getProfileNotifications(this.defaultNotificationFilter);
                this.mapDivisions();
            }
        });
        this.mainMenuService.refreshProfileNotificationsHook.subscribe((val: boolean) => {
            if (val) {
                this.getProfileNotifications(this.defaultNotificationFilter);
            }
        });
        // TP48272 : Continous call to check CHAT function visibility
        window.setInterval(() => {
            this.getChatButtonStatus();
        }, 5000);
    }

    logout() {
        const httpOptions = {
            headers: new HttpHeaders({
                'x-session-id': localStorage.clientSessionId,
                'x-user-id': localStorage.username,
                'Authorization': `Bearer ${localStorage.oauthToken}`
            })
        };
        this.router.navigate(['/user/login']).then((res: any) => {
            if (res) {
                localStorage.clear();
                sessionStorage.clear();
                this.authService.setLogInStatus(false);
                this.genericService.setCustomerByNameData(null);
                this.authService.logOutForSessionExpiry(httpOptions).subscribe(serviceResponse => {
                    this.isSpire = false;
                    console.log(serviceResponse);
                }, err => {
                    console.log(err);
                });
            }
        });
    }

    getChatButtonStatus() {
        this.genericService.getChatButtonStatus().subscribe(
            (res: any) => {
                res ? this.isChatEnabled = true : this.isChatEnabled = false;
            },
            error => {
                console.debug('Chat button error', error.error);
            }
        );
    }

    openChatModal() {
        if (this.isLoggedIn) {
            this.policyList = [];
            this.getPolicies('openChat');
            this.policyList = this.policyDetails.map((policy: any) => policy.policyNumber);
            console.log(this.policyDetails, this.policyList);
            this.chatModal.show();
        } else {
            this.chatModal.show();
        }
    }

    mapDivisions() {
        const rObj: any = [];
        this.policyDetails.map((obj: any) => {
            rObj.push(obj.division);
            if (obj.companionPolicyDivision) {
                rObj.push(obj.companionPolicyDivision);
            }
        });
        this.divisions = rObj.filter(function (item: any, i: any, ar: any) {
            return ar.indexOf(item) === i;
        });
        this.isSpire = this.divisions.indexOf('SPIRE') !== -1;
    }

    // TP48272: Update Hidden field value 'author' and 'email'
    // NOTE: taken from Existing Sahara code
    updateAuthor(theForm: any) {
        if (theForm.extensionField_Name) {
            if (theForm.extensionField_Name.value !== '') {
                theForm.author.value = theForm.extensionField_Name.value;
                theForm.extensionField_Name.name = 'extensionField_h_Name';
            }
        }
        if (theForm.extensionField_Email) {
            if (theForm.extensionField_Email.value !== '') {
                theForm.author.value = theForm.extensionField_Email.value;
                theForm.extensionField_Email.name = 'extensionField_h_Email';
            }
        }
    }

    submitChatForm() {
        if (this.chatForm.value.extensionField_Email) {
            this.spinner.showLoader(true);
            this.genericService.validateEmail(
                {
                    'email': this.chatForm.value.extensionField_Email.trim(),
                    'registration': false,
                }).subscribe(
                (emailRes: any) => {
                    this.spinner.showLoader(false);
                    if (emailRes.is_valid) {
                        this.emailErrMsg = '';
                        this.finalFormSubmit();
                    } else if (!emailRes.is_valid) {
                        this.emailErrMsg = emailRes.message;
                        this.chatFormSubmitted = true;
                    }
                },
                error => {
                    this.emailErrMsg = 'Error in validating email';
                    this.spinner.showLoader(false);
                    console.log(error);
                });
        } else {
            this.emailErrMsg = '';
            this.finalFormSubmit();
        }
    }

    finalFormSubmit() {
        const extensionField_Name = this.chatForm.value.extensionField_Name;
        const extensionField_AddressLine1 = this.chatForm.value.extensionField_AddressLine1;
        const extensionField_City_State = this.chatForm.value.extensionField_City_State;
        this.chatForm.patchValue({
            extensionField_Name: extensionField_Name ? extensionField_Name.trim() : null,
            extensionField_AddressLine1: extensionField_AddressLine1 ? extensionField_AddressLine1.trim() : null,
            extensionField_City_State: extensionField_City_State ? extensionField_City_State.trim() : null,
        });
        // console.log(this.chatForm, this.chatForm.value);
        if (this.chatForm.valid) {
            this.HTMLChatForm.nativeElement.submit();
            this.closeChatModal();
        } else {
            this.chatFormSubmitted = true;
        }
    }

    closeChatModal() {
        this.chatModal.hide();
        this.resetAllChatFormValues();
    }

    resetAllChatFormValues() {
        this.chatFormSubmitted = false;
        this.chatForm.reset();
        // To reset default value of SELECT dropdown to 'Billing/Policy Help'
        if (this.isLoggedIn) {
            this.chatForm.patchValue({
                extensionField_ccxqueuetag: 'Chat_Csq15',
                extensionField_Email: this.userEmail,
                extensionField_Policy_Number: ''
            });
        } else {
            this.chatForm.patchValue({
                extensionField_ccxqueuetag: 'Chat_Csq15',
                extensionField_Email: '',
                extensionField_Policy_Number: ''
            });
        }
    }

    navigateByLogo() {
        if (this.isLoggedIn) {
            this.router.navigate(['/home']);
        } else {
            this.router.navigate(['/user/login']);
            this.genericService.setCustomerByNameData(null);
        }
    }

    getPolicies(type: any) {
        this.spinner.showLoader(true);
        const serviceData = this.genericService.customerData;
        if (!serviceData) {
            this.genericService.getPolicyListByCustomerName(
                {
                    username: localStorage.getItem('username')
                }
            ).subscribe(
                (res: any) => {
                    this.spinner.showLoader(false);
                    if (res.response) {
                        this.policyDetails = res.response.policies;
                        this.userEmail = res.response.email;
                        this.genericService.setCustomerByNameData(res.response);
                        if (type === 'contactUs') {
                            this.getContactInfo();
                        }
                    }
                },
                error => {
                    this.spinner.showLoader(false);
                    console.log(error);
                });
        } else {
            this.spinner.showLoader(false);
            this.policyDetails = serviceData.policies;
            this.userEmail = serviceData.email;
            if (type === 'contactUs') {
                this.getContactInfo();
            }
        }
    }

    getContactInfo() {
        this.spinner.showLoader(true);
        const rObj: any = [];
        this.policyDetails.map((obj: any) => {
            rObj.push(obj.division);
            if (obj.companionPolicyDivision) {
                rObj.push(obj.companionPolicyDivision);
            }
        });
        const divisions = rObj.filter(function (item: any, i: any, ar: any) {
            return ar.indexOf(item) === i;
        });
        const payload = {
            'divisions': divisions
        };
        this.userService.getContactInfo(payload).subscribe(
            (res: any) => {
                console.log(res);
                this.spinner.showLoader(false);
                this.contactInfo = res;
            },
            error => {
                this.spinner.showLoader(false);
                console.log(error);
            });
    }

    getProfileNotifications(selectedFilter: string) {
        this.spinner.showLoader(true);
        this.payload = {
            status: selectedFilter,
            userName: localStorage.getItem('username')
        };
        this.userProfileService.getProfileNotifications(this.payload).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.status === 'SUCCESS') {
                    this.notificationsPayload = res.payload.profileNotifications;
                    this.notificationDetails = this.notificationsPayload.notificationDetails;
                }
            },
            error => {
                console.log(error);
                this.spinner.showLoader(false);
            });
    }

    showWarningModal(modalData: any) {
        this.selectedNotification = modalData;
        switch (modalData?.type) {
            case "delete":
                this.openNotificationWarningModal = true;
                this.modalMessage = 'Are you sure you want to delete this alert? Once deleted this alert cannot be restored.'
                break;
            case "archive":
                this.openNotificationWarningModal = true;
                this.modalMessage = 'This message will be archived.'
                break;
        }
    }

    filterNotifications(state: any) {
        this.selectedFilter = state;
        if (this.selectedFilter) {
            this.getProfileNotifications(state);
        }
    }

    //stopping event to parent element in order to stay open notification popup.
    stopEventPropagation(event: any) {
        event?.stopPropagation()
    }


    onModalConfirmClick(event: any) {
        event.stopPropagation();
        if (this.selectedNotification?.type === 'delete') {
            this.updateSelectedNotificationStatus(this.selectedNotification?.notification?.id, 'DELETED');
        } else if (this.selectedNotification?.type === 'archive') {
            this.updateSelectedNotificationStatus(this.selectedNotification?.notification?.id, 'ARCHIVED');
        }
    }

    markUnreadAsRead() {
        if (this.notificationsPayload?.numUnRead === 0) {
            this.getProfileNotifications(this.defaultNotificationFilter);
        }
        if (this.notificationsPayload?.numUnRead > 0 && this.notificationDetails?.length > 0) {
            const unreadNotificationIds = this.notificationDetails
                .filter((notif: any) => notif.notificationStatus === 'UNREAD')
                .map((notif: any) => notif.id);
            this.payload = {
                "ids": unreadNotificationIds.map((id: number) => id.toString()),
                newStatus: 'READ'
            }
            this.updateProfileNotifications(this.payload);
        }
    }

    updateSelectedNotificationStatus(id: number, notificationState: string) {
        this.payload = {
            "ids": [id.toString()],
            newStatus: notificationState
        }
        this.updateProfileNotifications(this.payload);
    }

    updateProfileNotifications(payload: any) {
        this.spinner.showLoader(true);
        this.userProfileService.updatePolicyNotifications(this.payload).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.status === 'SUCCESS') {
                    this.getProfileNotifications(this.selectedFilter ? this.selectedFilter : this.defaultNotificationFilter);
                }
            },
            error => {
                this.spinner.showLoader(false);
            });
    }
}
