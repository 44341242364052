import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {LoaderService} from '../../services/loader.service';
import {UpdateRecurringConfirmService} from './update-recurring-confirm.service';

@Component({
    selector: 'app-update-recurring-confirm-dialog',
    templateUrl: './update-recurring-confirm-dialog.component.html'
})
export class UpdateRecurringConfirmDialogComponent implements OnInit {
    @ViewChild('updateRecurringPaymentConfirmation') updateRecurringPaymentConfirmation: ModalDirective;
    @Input() policyNumber: string;

    constructor(private router: Router,
                private spinner: LoaderService,
                private updateRecurringConfirmService: UpdateRecurringConfirmService) {
    }

    ngOnInit() {
    }

    navidateToUpdateRecurringPaymentDetails() {
        localStorage.setItem('isUpdateRecurringPayment', 'true');
        if (localStorage.oauthToken) {
            this.router.navigate([`/policy-dashboard/${this.policyNumber}/payment/payment-summary`],
                {queryParams: {autoPaymentUpdate: 'true'}});
        } else {
            this.router.navigate([`/user/login`],
                {queryParams: {policyNumber: this.policyNumber}});
        }
    }

    get isLoggedIn() {
        if (localStorage.oauthToken) {
            return true;
        } else {
            return false;
        }
    }

    doneClicked() {
        localStorage.removeItem('isUpdateRecurringPayment');
        this.updateRecurringPaymentConfirmation.hide();
    }

    ngAfterViewInit() {
        this.checkForUpdateRecurringPayment();
    }

    checkForUpdateRecurringPayment() {
        this.spinner.showLoader(true);
        this.updateRecurringConfirmService.canUpdatePaymentInformation(
            {
                policyNumber: this.policyNumber
            }).subscribe(
            (res: any) => {
                this.spinner.showLoader(false);
                if (res.success == true && res.response == true) {
                    this.updateRecurringPaymentConfirmation.show();
                }
            }, error => {
                this.spinner.showLoader(false);
            });
    }

}
