import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Directive({selector: '[appExpiryDateValidator]'})
export class ExpiryDateValidatorDirective implements OnInit {
    @Output() validExpiry = new EventEmitter<boolean>();
    @Output() expiryDate = new EventEmitter<string>();
    private el: any;

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
    }

    @HostListener('keyup', ['$event']) onkeyup(event: any) {
        const value = event.target.value;
        let expiryValid = false;
        if (event.key !== 'Backspace') {
            this.el.value = this.formatExpiryDate(value);
            expiryValid = this.validateExpiryDate(this.el.value);
        }
        this.expiryDate.emit(this.el.value);
        this.validExpiry.emit(expiryValid);
    }

    validateExpiryDate(date: any) {
        const controlValue = date.split('/');
        let isValid = false;
        if (controlValue.length > 1) {
            const month = controlValue[0].replace(/^\s+|\s+$/g, '');
            const year = controlValue[1].replace(/^\s+|\s+$/g, '');
            if (year.length === 4) {
                isValid = true;
            }
        }
        return isValid;
    }

    formatExpiryDate(number: any) {
        // remove all non-number character from the value
        let cleanNumber = '';
        for (let i = 0; i < number.length; i++) {
            if (i === 1 && number.charAt(i) === '/') {
                cleanNumber = 0 + number.charAt(0);
            }
            if (/^[0-9]+$/.test(number.charAt(i))) {
                cleanNumber += number.charAt(i);
            }
        }

        let formattedMonth = '';
        for (let j = 0; j < cleanNumber.length; j++) {
            if (/^[0-9]+$/.test(cleanNumber.charAt(j))) {
                //  if the number is greater than 1 append a zero to force a 2 digit month
                if (j === 0 && parseInt(cleanNumber.charAt(j), 0) > 1) {
                    formattedMonth += 0;
                    formattedMonth += cleanNumber.charAt(j);
                    formattedMonth += '/';
                } else if (j === 1) {
                    formattedMonth += cleanNumber.charAt(j);
                    formattedMonth += '/';
                } else if (j === 2 && parseInt(cleanNumber.charAt(j), 0) < 2) {
                    formattedMonth += '20' + cleanNumber.charAt(j);
                } else if (j === 2 && cleanNumber.length > 6) {
                    formattedMonth = cleanNumber.substring(0, 2) + '/' + cleanNumber.substring(2, 6);
                    break;
                } else {
                    formattedMonth += cleanNumber.charAt(j);
                }
            }
        }
        return formattedMonth;
    }
}
