import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-renewal-modal',
    templateUrl: './renewal-modal.component.html',
    styleUrls: ['./renewal-modal.component.scss']
})
export class RenewalModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private dialogRef: MatDialogRef<RenewalModalComponent>) {

    }

    ngOnInit(): void {

    }

    isNumber(val: any) {
        return Number(val);
    }

    discard() {
        this.dialogRef.close(true);
    }

}
