import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-SessionTimeOut-modal',
    templateUrl: './sessiontimeout-modal.html',
    styles: [`    .modal-header {
    border-bottom: none;
    justify-content:center;
}`,
        `h4 {
  font-size: 1.25rem;
  font-family: 'Roboto-Regular',sans-serif;
}`,
        `.modal-body {
            font-family: 'Roboto-Regular', sans-serif
        }`,
        `    .modal-footer {
  border-top: none;padding:1.5rem;
}`,
        `  .btn-primary {
  font-family: 'Roboto-Light', sans-serif;
  width: 150px;
}`
    ]
})
export class SessionTimeOut implements OnInit {
    title = '';
    message = '';
    confirm = '';
    cancel: any;
    @Output() action: any = new EventEmitter<boolean>(false);

    constructor(public bsModalRef: BsModalRef) {
    }

    ngOnInit() {
    }

    onModalHidden() {
        this.action.emit(true);
        this.bsModalRef.hide();
    }

}
