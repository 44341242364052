import {Component, OnInit} from '@angular/core';
import {LoaderService} from './services/loader.service';
import {NavigationEnd, Router} from '@angular/router';
import {DynamicScriptLoaderService} from "./services/dynamic-script-loader.service";
import {EnvConfig} from "./services/env-config";

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    showLoader: boolean;

    constructor(
        private loaderService: LoaderService,
        private router: Router,
        private dynamicScriptLoader: DynamicScriptLoaderService,
        private envConfig: EnvConfig
    ) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                (window as any)['dataLayer'] = (window as any)['dataLayer'] || [];
                (window as any)['dataLayer'].push({
                    'event': 'Pageview',
                    'url': event.urlAfterRedirects
                });
            }
        });
    }

    ngOnInit() {
        const deploymentId = this.envConfig.getGenesysDeploymentId();
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = "(function (g, e, n, es, ys) {\n" +
            "      g['_genesysJs'] = e;\n" +
            "      g[e] = g[e] || function () {\n" +
            "        (g[e].q = g[e].q || []).push(arguments)\n" +
            "      };\n" +
            "      g[e].t = 1 * new Date();\n" +
            "      g[e].c = es;\n" +
            "      ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);\n" +
            "    })(window, 'Genesys', 'https://apps.usw2.pure.cloud/genesys-bootstrap/genesys.min.js', {\n" +
            "      environment: 'prod-usw2',\n" +
            "      deploymentId: '" + deploymentId + "'\n" +
            "    });";
        document.getElementsByTagName('head')[0].appendChild(script);

        this.loaderService.status.subscribe((val: boolean) => {
            setTimeout(() => {
                this.showLoader = val;
            }, 10);
        });

        this.loadScripts();
    }

    private loadScripts() {
        // You can load multiple scripts by just providing the key as argument into load method of the service
        this.dynamicScriptLoader.load('oneInc').then(data => {
            // Script Loaded Successfully
            $('#portalOneContainer').portalOne();
        }).catch(error => console.log(error));
    }
}

