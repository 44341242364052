import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApmService } from '@elastic/apm-rum-angular';

@Injectable()
export class EnvConfig {
  private activeProfile: string;
    envUrl = {
        apiURL: '',
        taskURL: '',
        gatewayURL: '',
        chatConfigURL: '',
        oneIncModalURL: '',
    };
    genesys = {
        deploymentId: ''
    };

  constructor(private http: HttpClient, private apmService: ApmService) {
  }

    getEnvironmentUrl(value: any) {
        switch (value) {
            case 'prod':
                this.envUrl = {
                    apiURL: 'https://customerportal.thig.com',
                    taskURL: 'https://tasknotes.thig.com',
                    gatewayURL: 'https://gateway.thig.com',
                    chatConfigURL: 'https://chatconfig.thig.com',
                    oneIncModalURL: 'https://portalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'orange':
                this.envUrl = {
                    apiURL: 'https://customerportal.orange.thig.com',
                    taskURL: 'https://tasknotes.orange.thig.com',
                    gatewayURL: 'https://gateway.orange.thig.com',
                    chatConfigURL: 'https://chatconfig.orange.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'unitqa':
                this.envUrl = {
                    apiURL: 'https://customerportal.unitqa.thig.com',
                    taskURL: 'https://tasknotes.unitqa.thig.com',
                    gatewayURL: 'https://gateway.unitqa.thig.com',
                    chatConfigURL: 'https://chatconfig.unitqa.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'uat':
                this.envUrl = {
                    apiURL: 'https://customerportal.uat.thig.com',
                    taskURL: 'https://tasknotes.uat.thig.com',
                    gatewayURL: 'https://gateway.uat.thig.com',
                    chatConfigURL: 'https://chatconfig.uat.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'red':
                this.envUrl = {
                    apiURL: 'https://customerportal.red.thig.com',
                    taskURL: 'https://tasknotes.red.thig.com',
                    gatewayURL: 'https://gateway.red.thig.com',
                    chatConfigURL: 'https://chatconfig.red.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'purple':
                this.envUrl = {
                    apiURL: 'https://customerportal.purple.thig.com',
                    taskURL: 'https://tasknotes.purple.thig.com',
                    gatewayURL: 'https://gateway.purple.thig.com',
                    chatConfigURL: 'https://chatconfig.purple.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'green':
                this.envUrl = {
                    apiURL: 'https://customerportal.green.thig.com',
                    taskURL: 'https://tasknotes.green.thig.com',
                    gatewayURL: 'https://gateway.green.thig.com',
                    chatConfigURL: 'https://chatconfig.green.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'beta':
                this.envUrl = {
                    apiURL: 'https://customerportal.beta.thig.com',
                    taskURL: 'https://tasknotes.beta.thig.com',
                    gatewayURL: 'https://gateway.beta.thig.com',
                    chatConfigURL: 'https://chatconfig.beta.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'yellow':
                this.envUrl = {
                    apiURL: 'https://customerportal.yellow.thig.com',
                    taskURL: 'https://tasknotes.yellow.thig.com',
                    gatewayURL: 'https://gateway.yellow.thig.com',
                    chatConfigURL: 'https://chatconfig.yellow.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            case 'local':
                this.envUrl = {
                    apiURL: 'http://localhost:4200',
                    taskURL: 'http://localhost:9926',
                    gatewayURL: 'https://gateway.uat.thig.com',
                    chatConfigURL: 'https://chatconfig.uat.thig.com',
                    oneIncModalURL: 'https://stgportalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
                break;
            default:
                this.envUrl = {
                    apiURL: 'https://customerportal.yellow.thig.com',
                    taskURL: 'https://tasknotes.yellow.thig.com',
                    gatewayURL: 'https://gateway.yellow.thig.com',
                    chatConfigURL: 'https://chatconfig.yellow.thig.com',
                    oneIncModalURL: 'https://portalone.processonepayments.com/Api/Api/Cdn/GenericModalV2/assets/js/PortalOne.js'
                };
        }
        if (value === 'prod') {
            this.genesys = {
                deploymentId: '2644c8b6-89bb-4f85-b738-d1ee97fec9b1'
            };
        } else {
            this.genesys = {
                deploymentId: '6688002b-edd9-47fb-ac16-7944a89cea8f'
            };
        }
        return this.envUrl;
    }

    getUrl() {
        return this.envUrl;
    }

    getGenesysDeploymentId() {
        return this.genesys.deploymentId;
    }

    getEnvironment(): Promise<any> {
        let url;
        let port = '';
        let scheme = 'https';
        if (location.hostname === 'localhost') {
            port = ':9091';
            scheme = 'http';
        }
        url = scheme + '://' + location.hostname + port + '/api/getActiveProfile';

    const result = this.http.get(url).toPromise()
      .then((data: any) => {
        this.activeProfile = data.activeprofile;
        this.setupApmAgent();
        return this.getEnvironmentUrl(this.activeProfile);
      })
      .catch((err: any) => {
        this.setupApmAgent();
        return Promise.resolve();
      });

    return result;
  }

  /**
   * Initializes the APM RUM agent
   */
  private setupApmAgent() {
    const serviceName = window.location.hostname.split('.')[0];
    this.apmService.init({
      serviceName: serviceName,
      serverUrl: 'https://f9b2e51833f54f2289a078f0b31352b5.apm.us-east-1.aws.cloud.es.io:443',
      environment: this.activeProfile,
      active: serviceName !== 'localhost', // Disables agent for localhost
      logLevel: 'info'
    });
  }

  getHttpHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'x-session-id': localStorage.clientSessionId,
        'x-user-id': localStorage.username,
        'Authorization': `Bearer ${localStorage.oauthToken}`
      })
    };
    return httpOptions;
  }
}
