import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {EnvConfig} from './env-config';
import {AuthService} from './auth.service';
import {SessionTimeOutModalService} from '../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import {HttpErrorHandlerService} from './http-error-handler.service';

@Injectable()
export class UserProfileService {
    private baseUrl = this.env.getUrl().apiURL;

    constructor(
        private http: HttpClient,
        private env: EnvConfig,
        private modalService: SessionTimeOutModalService,
        private authService: AuthService,
        private errorHandler: HttpErrorHandlerService) {
    }

    changePassword(data: any) {
        const apiUrl = `${this.baseUrl}/api/changepassword`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            }
        )
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    getUserProfileData(data: any, isExpressFnol?: any) {
        const payload = {'user_name': data};
        const apiUrl = `${this.baseUrl}/api/updateProfile/getUserDetails`;
        let httpHeaders = this.env.getHttpHeaders();
        return this.http.post(apiUrl, payload, {
                observe: 'response',
                headers: isExpressFnol ? {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.claimSessionId,
                    'x-session-id': localStorage.clientSessionId,
                    'Authorization': `Bearer ${localStorage.xauthToken}`
                } : httpHeaders?.headers
            }
        )
            .pipe(
                map((res: any) => {
                    if (!isExpressFnol) {
                        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    }
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService, isExpressFnol);
                })
            );
    }

    updateUserProfilePreferenceData(data: any, isExpressFnol?: any) {
        const apiUrl = `${this.baseUrl}/api/updateProfile/updateUserPreferences`;
        let httpHeaders = this.env.getHttpHeaders();
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                headers: isExpressFnol ? {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.claimSessionId,
                    'x-session-id': localStorage.clientSessionId,
                    'Authorization': `Bearer ${localStorage.xauthToken}`
                } : httpHeaders?.headers
            }
        )
            .pipe(
                map((res: any) => {
                    if (!isExpressFnol) {
                        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    }
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService, isExpressFnol);
                })
            );
    }

    updateUserProfileData(data: any, isExpressFnol?: any) {
        const apiUrl = `${this.baseUrl}/api/updateProfile/updateEmailPhone`;
        let httpHeaders = this.env.getHttpHeaders();
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                headers: isExpressFnol ? {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.claimSessionId,
                    'x-session-id': localStorage.clientSessionId,
                    'Authorization': `Bearer ${localStorage.xauthToken}`
                } : httpHeaders?.headers
            }
        )
            .pipe(
                map((res: any) => {
                    if (!isExpressFnol) {
                        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    }
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService, isExpressFnol);
                })
            );
    }

    updateUserSecurityData(data: any) {
        const apiUrl = `${this.baseUrl}/api/updateUserSecurity`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    getEDocsDetails(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/getEDocsDetails`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            }
        )
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    edocsEnroll(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/eDocsEnrollment `;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    eDocsGlobalEnrollment(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/eDocsGlobalEnrollment `;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    edocsWithdraw(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/eDocsWithdraw`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    edocsNoThanks(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/eDocsNoThanks`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    edocsRemindMeLater(data: any) {
        const apiUrl = `${this.baseUrl}/api/eDocs/eDocsRemindMeLater`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    getNotifications(data: any) {
        const apiUrl = `${this.baseUrl}/api/notification/getClaimAndPolicyNotifications`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    getProfileNotifications(data: any) {
        const apiUrl = `${this.baseUrl}/api/notification/getProfileNotifications`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    updateClaimNotifications(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/tasknotes/insured/updateNotifications`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    updatePolicyNotifications(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/policynotes/api/open/insured/updateNotifications`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    getPreferences(data: any) {
        const apiUrl = `${this.baseUrl}/api/policy/getPolicyPreference`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }

    updatePolicyPreferences(data: any, isExpressFnol?: any) {
        const apiUrl = `${this.baseUrl}/api/policy/updatePolicyPreference`;
        let httpHeaders = this.env.getHttpHeaders();
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                headers: isExpressFnol ? {
                    'Content-Type': 'application/json',
                    'x-auth-token': localStorage.claimSessionId,
                    'x-session-id': localStorage.clientSessionId,
                    'Authorization': `Bearer ${localStorage.xauthToken}`
                } : httpHeaders?.headers
            }
        )
            .pipe(
                map((res: any) => {
                    if (!isExpressFnol) {
                        localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    }
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService, isExpressFnol);
                })
            );
    }


    getDeclaration(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/oasis/api/customerPolicyInfo/getSDec`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            })
            .pipe(
                map((res: any) => {
                    localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                    return res.body;
                }),
                catchError((error) => {
                    return this.errorHandler.handleError(error, this.modalService, this.authService);
                })
            );
    }
}
