/* tslint:disable:no-trailing-whitespace */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
// application configuration file
import {EnvConfig} from './env-config';
import {AuthService} from './auth.service';
import {SessionTimeOutModalService} from '../shared/sessiontimeout-modal/sessiontimeout-modal.service';
import {HttpErrorHandlerService} from './http-error-handler.service';
import {ProductLineService} from "./product-line.service";

declare var $: any;

@Injectable()
export class OneIncService {

    constructor(
        private http: HttpClient,
        private env: EnvConfig,
        private modalService: SessionTimeOutModalService,
        private authService: AuthService,
        private productLineService: ProductLineService,
        private errorHandler: HttpErrorHandlerService) {
    }

    oneIncData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private baseUrl = this.env.getUrl().apiURL;
    private gatewayUrl = this.env.getUrl().gatewayURL;

    // This service is to know whether one-inc is available or not
    oneIncAvailable() {
        const apiUrl = `${this.baseUrl}/api/payments/OneInc/isAvailable`;
        return this.http.get(apiUrl).pipe(
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    // This service is to get the session ID to open makePayment modal
    getPaymentSessionId(data: any) {
        const apiUrl = `${this.baseUrl}/api/payments/OneInc/createSessionId`;
        return this.http.post(apiUrl, data).pipe(
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    // This service is to know whether one-inc is available or not for commercial policies (Quick Pay CL)
    oneIncAvailableForCommercialLines(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/payment-service/v1/one-inc/check-service-availability`;
        return this.http.post(apiUrl, data).pipe(
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    // This service is to get the session ID to open makePayment modal for commercial policies (Quick Pay CL)
    getPaymentSessionIdForCommercialLines(data: any) {
        const apiUrl = `${this.baseUrl}/proxyservice/payment-service/v1/one-inc/get-session-key`;
        return this.http.post(apiUrl, data).pipe(
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    // This service is to know the processing fee and total amount
    getConvenienceFee(data: any) {
        const apiUrl = `${this.baseUrl}/api/payments/OneInc/getFee`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            }
        ).pipe(
            map((res: any) => {
                localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                return res.body;
            }),
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    //This service is to save payment details for making one time payment
    saveOneIncPaymentDetails(data: any) {
        const apiUrl = `${this.baseUrl}/api/payments/OneInc/savePaymentDetails`;
        return this.http.post(apiUrl, data,
            {
                observe: 'response',
                ...this.env.getHttpHeaders()
            }
        ).pipe(
            map((res: any) => {
                localStorage.hostServerName = res.headers.get('X-SERVER-HOSTNAME');
                return res.body;
            }),
            catchError((error) => {
                return this.errorHandler.handleError(error, this.modalService, this.authService);
            })
        );
    }

    oneIncSavedPayment(selectedPaymentMode: any, userDetails: any, policyData: any, sessionId: any) {
        const paymentType = selectedPaymentMode === 'check-payment' ? 'ECheck' : 'CreditCard';
        $('#portalOneContainer').portalOne();
        const portalOne = $('#portalOneContainer');

        portalOne.data('portalOne')
            .savePaymentMethod({
                'paymentCategory': paymentType,
                'billingZip': policyData.address.postalCode,
                'policyHolderName': userDetails.firstName + ' ' + userDetails.lastName,
                'referenceNumber': policyData.policyNumber,
                'confirmationDisplay': 'false',
                'sessionId': sessionId
            });
        this.oneIncEvents();
        this.oneIncSavedPaymentEvents();
    }

    oneIncMakePayment(selectedPaymentMode: any, feeContext: any, paymentData: any, sessionId: any, policyData: any) {
        const paymentType = selectedPaymentMode === 'check-payment' ? 'ECheck' : 'CreditCard';
        // had to do this ternary because of the structure difference between product lines
        const accountGroupCode = this.productLineService.getProductLine() === 'CL' ?
            policyData.response.data.companyShortCode : policyData.response.policy.companyShortCode;
        $('#portalOneContainer').portalOne();
        const portalOne = $('#portalOneContainer');

        portalOne.data('portalOne').makePayment({
            'accountGroupCode': accountGroupCode,
            'paymentCategory': paymentType,
            'feeContext': feeContext,
            'minAmountDue': paymentData.amount,
            'amountContext': 'AmountDueOnly',
            'policyHolderName': '',
            'referenceNumber': paymentData.policyNumber,
            'sessionId': sessionId,
            'confirmationDisplay': false
        });
        this.oneIncEvents();
        this.oneIncMakePaymentEvents();
    }

    oneIncEvents() {
        const scope = this;
        const portalOne = $('#portalOneContainer');
        portalOne.one('portalOne.load', function () {
            scope.setOneIncData('load', {});
        });

        portalOne.one('portalOne.unload', function () {
            scope.setOneIncData('unload', {});
        });

        portalOne.one('portalOne.error', function (e: any, data: any) {
            scope.setOneIncData('error', data);
        });
    }

    oneIncSavedPaymentEvents() {
        const portalOne = $('#portalOneContainer');
        const scope = this;

        portalOne.one('portalOne.saveComplete', function (x: any, data: any) {
            scope.setOneIncData('saveComplete', data);
        });

        portalOne.one('portalOne.saveCanceled', function () {
            scope.setOneIncData('saveCanceled', {});
        });
    }

    oneIncMakePaymentEvents() {
        const portalOne = $('#portalOneContainer');
        const scope = this;

        portalOne.one('portalOne.paymentComplete', function (e: any, data: any) {
            scope.setOneIncData('paymentComplete', data);
        });

        portalOne.one('portalOne.paymentCanceled', function () {
            scope.setOneIncData('paymentCanceled', {});
        });
    }

    setOneIncData(event: any, data: any) {
        const oneIncData = {
            'event': event,
            'data': data
        };
        this.oneIncData.next(oneIncData);
    }
}
