import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {BehaviorSubject} from 'rxjs';
import {CustomModalComponent} from './custom-modal.component';

@Injectable()
export class ModalHelperService {
    constructor(private modalService: BsModalService) {
    }

    modalConfig = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };
    bsModalRef: BsModalRef;
    private isModalClosed = new BehaviorSubject<boolean>(false);

    showConfirm(title?: string, message?: string, confirm?: string, cancel?: string) {
        const initialState = {
            message: message,
            title: title,
            confirm: confirm,
            cancel: cancel
        };
        this.bsModalRef = this.modalService.show(
            CustomModalComponent,
            Object.assign({}, this.modalConfig, {initialState})
        );

        this.bsModalRef.content.action.subscribe((value: any) => {
            value ? this.isModalClosed.next(true) : this.isModalClosed.next(false);
        });

        this.bsModalRef.content.hide = () => {
            // Handle promise data
            this.bsModalRef.hide();
        };
    }

    get closeModal() {
        return this.isModalClosed.asObservable();
    }

    setCloseModal(value: any) {
        this.isModalClosed.next(value);
    }
}
