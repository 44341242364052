import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

@Directive({selector: '[appCardNumValidator]'})
export class CardNumValidatorDirective implements OnInit {
    @Output() cardType = new EventEmitter<string>();
    @Output() validCard = new EventEmitter<boolean>();
    private el: any;

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    ngOnInit() {
    }

    @HostListener('keyup', ['$event']) onKeyUp(event: any) {
        const value = event.target.value;
        // Formatting the number by removing unwanted characters and adding spaces in between
        this.el.value = value.replace(new RegExp(/[^0-9 ]/, 'g'), '');
        this.el.value = this.formatCardNumber(this.el.value);
        // Fetching card type
        const cleanNumber = value.replace(new RegExp(/[^0-9]/, 'g'), '');
        this.cardType.emit(this.getCardType(cleanNumber));
        // run the Luhn algorithm on the number if it is at least equal to the shortest card length
        let cardValid = false;
        if (cleanNumber.length > 12) {
            cardValid = this.luhn(cleanNumber);
            this.validCard.emit(cardValid);
        }
    }

    formatCardNumber(value: any) {
        const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        const matches = v.match(/\d{4,19}/g);
        const match = matches && matches[0] || '';
        const parts = [];
        for (let i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            return parts.join(' ');
        } else {
            return value;
        }
    }

    luhn(number: any) {
        let digit, digits, j, len, odd, sum;
        odd = true;
        sum = 0;
        digits = (number + '').split('').reverse();
        for (j = 0, len = digits.length; j < len; j++) {
            digit = digits[j];
            digit = parseInt(digit, 10);
            if ((odd = !odd)) {
                digit *= 2;
            }
            if (digit > 9) {
                digit -= 9;
            }
            sum += digit;
        }
        return sum % 10 === 0;
    }

    getCardType(number: any) {
        let cardName = 'invalid';
        const cardTypes = [
            {
                name: 'amex',
                pattern: /^3[47]/,
                valid_length: [15]
            }, {
                name: 'visa',
                pattern: /^4/,
                valid_length: [16]
            }, {
                name: 'mastercard',
                pattern: /^5[1-5]/,
                valid_length: [16]
            }, {
                name: 'discover',
                pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
                valid_length: [16]
            }
        ];
        // test the number against each of the above card types and regular expressions
        for (let i = 0; i < cardTypes.length; i++) {
            if (number.match(cardTypes[i].pattern)) {
                // if a match is found return card name, which will be appended as class name in HTML
                cardName = cardTypes[i].name;
            }
        }
        return cardName;
    }

}
