import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {OverlayContainer} from "@angular/cdk/overlay";
import {ModalHelperService} from "../custom-modal/modal-helper.service";
import {MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    @Input() notifications: any;
    @Input() policy: any;
    @Input() notificationLocation: string;
    @Input() showFiltersPanel: any = false;
    @Input() showNotificationsPanel: boolean = true;
    @Input() selectedFilter: any;
    @Input() filterOptions: any[];
    @Output() showWarningModal: EventEmitter<any> = new EventEmitter();
    @Output() filterNotifications: EventEmitter<any> = new EventEmitter();
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
    modalMsg: any;
    selectedIndex: any = -1;
    isFilterApplied: boolean = false;
    showNotificationNavModal: boolean = false;
    lastViewedTabIndex: number = 0;
    defaultFilterOptions: any[] = [
        {id: "READ", description: "Read", checked: false},
        {id: "UNREAD", description: "Unread", checked: false},
        {id: "ARCHIVED", description: "Archived", checked: false},
        {id: "ALL", description: "All", checked: false},
    ];

    constructor(private overlayContainer: OverlayContainer,
                private modalService: ModalHelperService) {
    }

    ngOnInit() {
        if (!this.filterOptions) {
            this.filterOptions = this.defaultFilterOptions;
        }
        if (this.selectedFilter !== null) {
            this.filterOptions.map((option: any, index: any) => {
                if (this.selectedFilter === option.id) {
                    option.checked = true;
                    this.selectedIndex = index;
                }
                return option;
            })
        }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent): void {
        this.lastViewedTabIndex = tabChangeEvent.index;
    }

    toggleFilterPanel() {
        this.showFiltersPanel = true;
        this.showNotificationsPanel = false;
    }

    toggleWarningModal(type: any, event: any, notification?: any, formattedAddress?: any, policyTerm?: any) {
        event.stopPropagation();
        this.trigger.closeMenu();
        setTimeout(() => {
            this.showWarningModal.emit({
                type: type,
                notification: notification ? notification : null,
                formattedAddress: formattedAddress ? formattedAddress : null,
                policyTerm: policyTerm ? policyTerm : null
            });
        });
    }

    onFilterOptionChange(opt: any, event: any, index: any) {
        this.selectedFilter = event.checked ? opt.id : 'NONARCHIVED';
        this.selectedIndex = this.selectedFilter !== 'NONARCHIVED' ? index : -1;
    }

    applyFilter(event: any) {
        event.stopPropagation();
        this.showFiltersPanel = false;
        this.showNotificationsPanel = true;
        this.isFilterApplied = true;
        this.filterNotifications.emit(this.selectedFilter);
    }

    //To stop closing the notification panel when clicked away from mat menu
    preventCloseOnClickOut() {
        this.overlayContainer.getContainerElement().classList.add('disable-backdrop-click');
    }
}
