import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import {CurrencyPipe} from './currency-formatter.pipe';

@Directive({selector: '[appCurrencyFormatter]'})
export class CurrencyFormatterDirective implements OnInit {

    private el: any;

    constructor(
        private elementRef: ElementRef,
        private currencyPipe: CurrencyPipe
    ) {
        this.el = this.elementRef.nativeElement;

    }

    ngOnInit() {
        this.el.value = this.currencyPipe.transform(this.el.value);
    }

    @HostListener('keyup', ['$event'])
    onKeyup(event: any) {
        this.el.value = this.el.value.replace(new RegExp(/[^0-9.]/, 'g'), '');
        const value = event.target.value;
        if (event.key !== 'Backspace') {
            // UAT defect # 47237: payment value rounding off to 2 digits after decimal, if amount is payAnotherDue
            // Handling as the user types
            if (value.indexOf('.') === -1) {
                this.el.value = value;
            } else if (value.indexOf('.') >= -1) {
                // tslint:disable-next-line:prefer-const
                let [integer, fraction = ''] = (value || '').replace('$' || '$$', '').split('.');
                fraction = fraction.length > 2 ? (fraction).substring(0, 2) : fraction;
                this.el.value = integer === '' ? null : integer + '.' + fraction;
            }
        }
    }

    @HostListener('focus', ['$event.target.value'])
    onFocus(value: any) {
        this.el.value = this.currencyPipe.parse(value); // opossite of transform
    }

    @HostListener('blur', ['$event.target.value'])
    onBlur(value: any) {
        this.el.value = this.currencyPipe.transform(value);
    }

}
